import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader=''
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
            <Button sx={styles.button}>Contact Me</Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  Hello, reader! I am Karthik working as a DiT from last 4 years.
                  With my time, I have developed scalable and maintainable test frameworks
                  for both API and UI in programming languages like Java and Python.
                </Text>
                <Text variant='p'>
                  During my initial days, I clearly remember struggling with understanding engineering
                  concepts and implementing test automation code for the simplest of things. I had to learn
                  on the job like most of us.
                </Text>
                <Text variant='p'>
                  The inspiration for starting test cult is to create a knowledge base for testers. Starting
                  from engineering concepts, simple code samples to complex automation frameworks with continuous
                  testing catering to all skill levels.
                </Text>
                <Text variant='p'>
                  I would love to collaborate and open the platform for like minded individuals.
                </Text>
                <Text variant='p'>
                  All things said, I hope the articles and tutorials will add value to you.
                  Suggestions and feedbacks towards improvement are welcome.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='My view on test automation'>
                <Card variant='paper'>
                  Maintaining automated tests not only help in faster product development cycles without compromising
                  on quality but also reduces the total effort required on the whole team to achieve it.
                  Often times testing team becomes a bottleneck
                  in the development cycle because of heavy reliance on manual verification.
                </Card>
              </Section>
              <Divider />
              <Section>
                <Card variant='paper'>
                  Role of a tester is to get out of developer's way instead of coming in.
                </Card>
              </Section>
              {/*<Divider />*/}
              {/*<Section title='Creative Designing'>*/}
              {/*  <Card variant='paper'>*/}
              {/*    A core responsibility of the designer's job is to present*/}
              {/*    information in a way that is both accessible and memorable.*/}
              {/*  </Card>*/}
              {/*</Section>*/}
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    photo: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
